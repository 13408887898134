import { css, html } from "lit"
import { ECComponent } from "./ec-component"
import { customElement } from 'lit/decorators.js'
import { ConfigType } from "../ConfigType"

declare const CONFIG: ConfigType

@customElement('ec-home-not-signed-in')
export class ECHomeNotSignedIn extends ECComponent {

  static styles = [
    ...ECComponent.styles,
    css`
      div.main {
        text-align: center;
      }
      div.sect1 {
        margin-top: 6vh;
      }
      img.logo {
        width: max(min(30vh, 30vw), 225px)
      }
      div.serviceName {
        font-size: max(min(8vw, 8vh), 48px);
        font-weight: bold;
      }
      div.sect2 {
        margin-bottom: 6vh;
      }
      div.purpose {
        font-size: max(min(2.75vw, 2.75vh), 24px);
        font-style: italic;
      }
      div.whySignUp {
        font-size: max(min(2vw, 2vh), 16px);
        margin-bottom: 2vh;
        font-style: italic;
        font-weight: bold;
      }
    `
  ]

  render() {
    return html`
      <div class="main">
        <div class="sect1">
          <div class="serviceName">${CONFIG.serviceName}</div>
          <div class="purpose">${CONFIG.purpose}</div>
        </div>
        <img class="logo" src="img/${CONFIG.logo ? CONFIG.logo : 'logo.svg'}" alt="logo alt" title="logo title">
        <div class="sect2">
          <div class="whySignUp">${CONFIG.whySignUp}</div>
          <button id="do" onclick="window.location.href='/users/_/signup';">Sign up</button>
        </div>
      </div>
    `
  }
}
