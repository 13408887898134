import { css, html } from "lit"
import { customElement, property } from 'lit/decorators.js'
import { ECComponent } from "./ec-component"
import { consume } from '@lit/context'
import { User, userContext } from "./ec-app"
import { ConfigType } from "../ConfigType"

declare const CONFIG: ConfigType

export type MenuItem = {
  icon: string,
  name: string,
  title: string,
  signedInOnly: boolean,
  signedOutOnly: boolean,
  debugOnly: boolean,
  action: (e: PointerEvent) => void
 }
export type MenuItems = MenuItem[]

/**
 * Menu component.
 * 
 * @attr title
 * @attr items - JSON of Item.
 * @attr open - Boolean for whether the menu starts open.  Default is "false".
 */
@customElement('ec-menu')
export class ECMenu extends ECComponent {
  @property({ type: String, reflect: true })
  title: string
  @property({ type: Array<MenuItem> })
  items: MenuItems
  @property({ type: Boolean, reflect: true })
  open: boolean
  @property({type: String })
  mainIconURL: string

  @consume({context: userContext, subscribe: true})
  @property({attribute: false})
  public user?: User;

  static styles = [
    ...ECComponent.styles,
    css`
      :host {
        font-size: 16px;
        color: var(--text-color);
        display: flex;
        flex-direction: column;
        background-color: transparent;
        user-select: none;
      }
      .head {
        display: flex;
        justify-content: space-between;
      }
      .menu {
        position: absolute;
        top: 48px;
        right: 1px;
        max-height: 0;
        opacity: 0;
        overflow-y: hidden;
        overflow-x: hidden;
        box-shadow: 1px 1px 1px 1px rgba(0,0,0,.6);
        transition: max-height .3s linear, opacity .3s linear;
      }
      .menu.open {
        max-height: 240px;
        opacity: 1;
        z-index: 10;
        background-color: var(--bgcolor);
      }
      .menu.closed {
        max-height: 0;
        opacity: 0;
      }
      ::slotted(p) {
        margin:0;
      }
      .item {
        color: var(--label-fgcolor);
        position: relative;
        display: flex;
        align-items: center;
        transition: padding .5s linear, background-color .25s linear, color .25s linear;
        opacity: 1;
        cursor: pointer;
        padding: 4px;
      }
      .item:hover {
        color: var(--menu-hilite-fgcolor);
        background-color: var(--menu-hilite-bgcolor);
      }
      span.menuItemName {
        margin-left: 20px;
        color: var(--label-fgcolor);
      }
      span.menuItemIcon {
        font-size: 90%;
      }
      span#userIcon {
        cursor: pointer;
      }
      img#avatar {
        cursor: pointer;
        object-fit: cover;
        object-position: top center;
        border-radius:50%;
        width: 24px;
        height: 24px;
      }
      .debugItem {
        background-color: var(--debug-bgcolor);
        color: var(--debug-fgcolor);
      }
    `
  ]

  constructor() {
    super();
    this.title = ''
    this.items = []
    this.open = false
    this.mainIconURL = ''
  }
  
  async connectedCallback() {
    super.connectedCallback();
    document.addEventListener('click', this.handleDocumentClick);
    document.addEventListener('keydown', this.handleKeyDown);
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('click', this.handleDocumentClick);
    document.removeEventListener('keydown', this.handleKeyDown);
  }
  
  handleDocumentClick = (event: MouseEvent) => {
    const path = event.composedPath();
    if (!path.includes(this))
      this.closeMenu();
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape')
      this.closeMenu();
  }
  toggleMenu() {
    this.open = !this.open;
  }
  closeMenu() {
    this.open = false;
  }
  itemClick(e: PointerEvent, item: MenuItem) {
    const path = e.composedPath()
    if (path.includes(this))
      item.action(e)
    this.closeMenu()
  }
  render() {
    return html`
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
      ${this.mainIconURL === ''
            ? html`<span id="userIcon" title="Sign up" class="material-icons" @click=${this.toggleMenu}>account_circle</span>`
            : html`<img id="avatar" src="${this.mainIconURL}" @click=${this.toggleMenu}/>`}
      <div class='menu ${this.open ? 'open' : 'closed' }'>
        ${this.items.map((item: MenuItem) =>
          ((item.signedInOnly && this.user?.signedIn) || (item.signedOutOnly && !this.user?.signedIn) || (item.debugOnly && CONFIG.debug))
            ? html`<span class='item ${CONFIG.debug && item.debugOnly ? 'debugItem' : ''}' @click='${(e: PointerEvent) => this.itemClick(e, item)}'}><span class="material-icons menuItemIcon" title="${item.title}">${item.icon}</span><span class="menuItemName">${item.name}</span></span>`
            : html``
        )}
      </div>`
  }
}
