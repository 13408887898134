import { css } from "lit"
import { property } from 'lit/decorators.js'
import { ECComponent } from "./ec-component.js"

export class ECSignBase extends ECComponent {

  @property({ type: Boolean })
  protected valid = false
  
  static styles = [
    ...ECComponent.styles,
    css`
      div#content {
        display: grid;
        grid-template-columns: minmax(10px, 1fr) minmax(10px, 1fr);
        gap: 12px 8px;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        width: min(100%, 600px);
      }

      div#callToJoin {
        font-size: 200%;
        font-weight: bold;
        text-align: center;
        color: var(--label-fgcolor);
        grid-column: 1/3;
      }
      div#orSignIn {
        font-style: italic;
        text-align: center;
        font-size: 75%;
        color: var(--label-fgcolor);
        grid-column: 1/3;
        margin-bottom: 16px;
      }
      a {
        color: var(--label-fgcolor);
      }
      div#content input {
        padding: 8px;
      }
      input#fullName {
        display: block;
        grid-column: 1/3;
      }
      input#email {
        display: block;
        grid-column: 1/3;
      }
      input#password {
        display: block;
        grid-column: 1/3;
        font-family: monospace;
      }
      #pwStrengthMeter {
        display: block;
        grid-column: 1/3;
        height: 5px;
        width: 100%;
        background-color: #ccc;
        margin-top: 4px;
      }
      #pwStrengthMeter span {
        display: block;
        height: 5px;
        border-radius: 2px;
        transition: all 500ms ease;
      }
      .strength-0 span {
        background-color: red;
        width: 5%;
      }
      .strength-1 span {
        background-color: orangered;
        width: 25%;
      }
      .strength-2 span {
        background-color: orange;
        width: 50%;
      }
      .strength-3 span {
        background-color: yellowgreen;
        width: 75%;
      }
      .strength-4 span {
        background-color: green;
        width: 100%;
      }
      div#pwFeedback {
        grid-column: 1/3;
        display: block;
        color: var(--label-fgcolor);
        font-size: 90%;
      }
      li.pwSuggestion {
        margin-top: 4px;
      }
      div.agree {
        font-size: 90%;
        color: var(--label-fgcolor);
        grid-column: 1/3;
        display: grid;
        grid-template-columns: 32px 1fr;
      }
      input#agreeTOSandPP, input#agreeNDA {
        grid-column: 1;
        width: 16px;
      }
      label.agree {
        cursor: pointer;
        grid-column: 2;
        text-align: justify
      }
      button#do {
        margin-top: 12px;
        margin-left: auto;
        margin-right: auto;
        grid-column: 1/3;
      }
      div.requiredDescription {
        font-size: 75%;
        color: var(--label-fgcolor);
        grid-column: 1/3;
        text-align: center;
      }
      span#hideShowPasswordWrap {
        font-size: 90%;
      }
      span#hideShowPassword {
        text-decoration: underline;
        cursor: pointer;
      }
      #notify {
        grid-column: 1/3;

      }
    `
  ]

  protected ui2jsObj(formRootId: string): Object {
    if (this.shadowRoot) {
      const inputElements = this.shadowRoot.getElementById(formRootId)?.getElementsByTagName('input')
      const userRequest = inputElements === undefined ?
        {}
        : Object.fromEntries([...inputElements].map(e => [e.id, e.type === 'checkbox' ? e.checked : e.value]))
      // console.log(`userRequest=${JSON.stringify(userRequest, null, 2)}`)
      return userRequest
    } else {
      console.error(`ERROR: this.shadowRoot is undefined`)
      return {}
    }
  }
  protected validate(_e: InputEvent) {
    const fields = this.ui2jsObj('content')
    this.valid = true // tentatively
    this.valid = this.valid && Object.values(fields).every(v => v !== "" && v !== false)
    this.render()
  }
}
