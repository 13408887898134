import { PropertyValueMap, css, html } from "lit"
import { customElement, property } from 'lit/decorators.js'
import { ECSignBase } from './ec-sign-base.js'
import { supabase } from "../Supabase.js"
import { ZxcvbnResult, zxcvbn } from '@zxcvbn-ts/core'
import { zxcvbnOptions } from '@zxcvbn-ts/core'
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common'
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en'
import { Router } from "@vaadin/router"
import { MD5 } from 'crypto-js'
import { ConfigType } from "../ConfigType.js"

declare const CONFIG: ConfigType

@customElement('ec-signup')
export class ECSignUp extends ECSignBase {

  @property({ type: Number })
  private pwScore = 0
  @property({ type: Boolean })
  private showPassword = false
  @property({ type: Object })
  private zxcvbnResp: ZxcvbnResult
  @property({ type: String })
  private focusId: string | undefined = undefined
  
  static pwStrengths = ['extremely weak', 'very weak', 'weak', 'acceptably strong', 'very strong']
  static styles = [
    ...ECSignBase.styles,
    css`
      div#pwFeedback {
        text-align: left;
      }
    `
  ]
  constructor() {
    super()
    const options = {
      // recommended
      dictionary: {
        ...zxcvbnCommonPackage.dictionary,
        ...zxcvbnEnPackage.dictionary
      },
      // recommended
      graphs: zxcvbnCommonPackage.adjacencyGraphs,
      // recommended, but causes reverse word warnings for non-words:
      // useLevenshteinDistance: true,
      // optional
      translations: zxcvbnEnPackage.translations,
    }
    zxcvbnOptions.setOptions(options)
    this.zxcvbnResp = zxcvbn("")
    this.pwScore = this.zxcvbnResp.score
  }
  protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    super.firstUpdated(_changedProperties)
    this.updatePwStrengthMeter()
    if (CONFIG.debug) {
      this.shadowRoot?.getElementById('fullName')?.setAttribute('value', 'Kenneth Hughes')
      this.shadowRoot?.getElementById('email')?.setAttribute('value', `kjh+${Math.floor(Math.random() * 1000000)}@entel.com`)
      this.shadowRoot?.getElementById('agreeTOSandPP')?.setAttribute('checked', 'true')
      this.shadowRoot?.getElementById('agreeNDA')?.setAttribute('checked', 'true')
      this.validate(new InputEvent('input'))
    }
  }
  protected pwFocus() {
    return (e: FocusEvent) => {
      this.focusId = (e.target as HTMLInputElement).id
    }
  }
  protected pwBlur() {
    return (_e: FocusEvent) => {
      // Delay to allow exiting click to register on target before hiding
      // (and moving) the password suggestions:
      setTimeout(() => {
        this.focusId = undefined;
      }, 200)
    }
  }
  private togglePasswordHideShow(e: PointerEvent) {
    this.showPassword = !this.showPassword
    this.focusId === "password"
    e.preventDefault() // prevent subsequent blur event on password input element
  }
  render() {
    return html`
      <div class="main">
        <div id="content">
          <div id="callToJoin">Sign up</div>
          <div id="orSignIn">Or <a href="/users/_/signin">sign in</a> to an existing account.</div>
          <input id="fullName" class="textBox" type="text" placeholder="Full name"
                data-part="fullName" @input=${this.validate}/>
          <input id="email" type="text" placeholder="Email address"
                data-part="email" @input=${this.validate}/>
          <input id="password" type="${ this.showPassword ? "text" : "password"}" placeholder="Password"
                  data-part="password" @input=${this.validate} @focus=${this.pwFocus()} @blur=${this.pwBlur()}/>
          <div id="pwStrengthMeter"><span></span></div>
          ${this.focusId === "password"
            ? html`<div id="pwFeedback">
                     <div id="pwStregthStatement">
                       Your password <span id="hideShowPasswordWrap">[ <span id="hideShowPassword" @mousedown=${this.togglePasswordHideShow}>${ this.showPassword ? "hide" : "show"}</span> ]</span> is ${ECSignUp.pwStrengths[this.pwScore]}${this.zxcvbnResp.feedback.warning === null && this.zxcvbnResp.feedback.suggestions.length === 0 ? '.' : ' so far:'}
                     </div>
                     <ul id="pwSuggestions">
                       ${this.zxcvbnResp.feedback.warning === null
                         ? html``
                         : html`<li class="pwSuggestion">${this.zxcvbnResp.feedback.warning}</li>`
                       }
                       ${this.zxcvbnResp.feedback.suggestions.map((s: string) => html`<li class="pwSuggestion">${s}</li>`)}
                     </ul>
                   </div>`
            : html``
          }
          <div class="agree">
            <input id="agreeTOSandPP" type="checkbox" data-part="agreeTOSandPP" @input=${this.validate}/>
            <label class="agree" for="agreeTOSandPP">I agree to the <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a> in exchange for access to this site.</label>
          </div>
          <div class="agree">
            <input id="agreeNDA" type="checkbox" data-part="agreeDNA"  @input=${this.validate}/>
            <label class="agree" for="agreeNDA">I agree to the <a href="#">Non-Disclosure Agreement</a> in exchange for access to pre-release features.</label>
          </div>
          <button id="do" @click=${this.signUp} part="button" ?disabled=${!this.valid}>Sign up</button>
          <div class="requiredDescription">
            All fields are required.
          </div>
        </div>
      </div>
    `
  }
  private async signUp(_e: Event) {
    const fields: UserRequest = this.ui2jsObj('content')
    const emailMD5 = MD5( fields.email!.trim().toLocaleLowerCase() ).toString()
    const { data, error } = await supabase.auth.signUp({
      email: fields.email!,
      password: fields.password!,
      options: {
        emailRedirectTo: `https://entel-common.com`,
        data: {
          email: fields.email!,
          full_name: fields.fullName,
          tos_pp: fields.agreeTOSandPP,
          nda: fields.agreeNDA,
          avatar_url: `https://www.gravatar.com/avatar/${emailMD5}`
        }
      },
    })
    console.log(`TODO: handle any errors here.  data=${JSON.stringify(data, null, 2)}; error=${JSON.stringify(error, null, 2)}`)
    Router.go('/')
  }

  protected validate(_e: InputEvent) {
    const fields: UserRequest = this.ui2jsObj('content')
    const password: string = fields.password!
    this.valid = true // tentatively
    this.zxcvbnResp = zxcvbn(password)
    this.pwScore = this.zxcvbnResp.score
    this.valid = this.valid && Object.values(fields).every(v => v !== "" && v !== false)
    this.valid = this.valid && this.zxcvbnResp.score > 2
    // this.valid = this.valid && password.length <= 72 // Supabase limit
    this.updatePwStrengthMeter()
    this.render()
  }
  private updatePwStrengthMeter() {
    const pwdStrength = this.shadowRoot?.getElementById('pwStrengthMeter') as HTMLInputElement
    pwdStrength.className = "strength-" + this.pwScore
  }
}
type UserRequest = {
  email?: string
  fullName?: string
  password?: string
  agreeTOSandPP?: boolean
  agreeNDA?: boolean
  avatar_url?: string
}