import { css, html } from "lit"
import { customElement, property } from 'lit/decorators.js'
import { ECComponent } from "./ec-component.js"
import { consume } from '@lit/context'
import { User, userContext } from "./ec-app.js";

@customElement('ec-user')
export class ECSettings extends ECComponent {
  @consume({context: userContext, subscribe: true})
  @property({attribute: false})
  public user?: User;

  static styles = [
    ...ECComponent.styles,
    css`
      div.main div.intro {
        font-size: min(4vw, 4vh);
        text-align: center;
        padding-left: 4vw;
        padding-right: 4vw;
        margin-top: 0.5vh;
        margin-bottom: 0.5vh;
      }
    `
  ]
  
  // connectedCallback(): void {
  //   super.connectedCallback()
  //   data = (await supabase.from('profiles').select('*').eq('id', user.id).limit(1).single()).data
  // }
  render() {
    return html`
      <div class="main">
        <div class="intro">Profile</div>
        ${Object.entries(this.user?.session || {}).map(([name, value]) =>
          {
            switch (name) {
              case "access_token":
                return html`` // because it's too long and hoses this simplistic layout.
              case "user":
                return Object.entries(value || {}).map(([userName, userValue]) =>
                  html`
                  <div class="namedValues">
                    <div class="name">${userName}</div>
                    <div class="value">${userValue}</div>
                  </div>`
                )
                break
              default:
                return html`
                  <div class="namedValues">
                    <div class="name">${name}</div>
                    <div class="value">${value}</div>
                  </div>`
            }
          }
        )}
      </div>
    `
  }
}
