import { css, html } from "lit"
import { customElement, property } from 'lit/decorators.js'
import { ECSignBase } from './ec-sign-base.js'
import { supabase } from "../Supabase.js"
import { ECNotification } from "./ec-notify.js"
import { Router } from "@vaadin/router"

type Fields = {
  email?: string
  password?: string
}

@customElement('ec-signin')
export class ECSignIn extends ECSignBase {

  @property({ type: Boolean })
  private showPassword = false
  private notification: ECNotification | undefined = undefined
  
  static styles = [
    ...ECSignBase.styles,
    css`
    ec-notify {
      margin-top: 48px;
    }`
  ]

  firstUpdated(changedProperties: Map<string, unknown>): void {
    super.firstUpdated(changedProperties)
    this.shadowRoot?.getElementById('email')?.focus()
  }

  render() {
    return html`
      <div class="main">
        <div id="content">
          <div id="callToJoin">Sign in</div>
          <div id="orSignIn">Or <a href="/users/_/signup">sign up</a>, or <a href="/users/_/reset-pw">reset password</a>.</div>
          <input id="email" type="text" placeholder="Email address"
                 data-part="email" @input=${this.validate}/>
          <input id="password" type="${ this.showPassword ? "text" : "password"}" placeholder="Password"
                data-part="password" @input=${this.validate} @keypress=${this.keyPress}/>
          <button id="do" type="button" @click=${this.signIn} part="button" ?disabled=${!this.valid}>Sign in</button>
          <ec-notify id="notify" .notification=${this.notification}></ec-notify>
        </div>
      </div>
    `
  }
  private keyPress(e: KeyboardEvent) {
    if (e.key === 'Enter')
      this.signIn(e)
  }
  private async signIn(_e: Event) {
    try {
      const fields: Fields = this.ui2jsObj('content')
      supabase.auth.signInWithPassword({
        email: fields.email!,
        password: fields.password!
      }).then(({ error }) => {
        // console.log('signInWithPassword: data=', data)
        // console.log('                    error=', error)
        if (error) {
          this.notification = {
            level: 'error',
            message: error?.message || 'Unknown sign in failure'
          }
          this.requestUpdate()
        } else {
          this.notification = {
            level: 'success',
            message: 'Sign in success'
          }
          this.requestUpdate()
          // Pause for a second before going to home page:
          // await new Promise(resolve => setTimeout(resolve, 1000))
          Router.go('/')
        }
      }).catch(error => {
        console.error(error)
        this.notification = {
          level: 'error',
          message: error?.message || 'Unknown sign in failure'
        }
        this.requestUpdate()
      })
    } catch (error) {
      console.error(error)
      this.notification = {
        level: 'error',
        message: (error as Error)?.message || 'Unknown sign in failure'
      }
      this.requestUpdate()
    }
  }

  protected validate(_e: InputEvent) {
    super.validate(_e)
  }
}
