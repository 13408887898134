import { css, html } from "lit"
import { customElement, property } from 'lit/decorators.js'
import { ECSignBase } from './ec-sign-base.js'
import { supabase } from "../Supabase.js"
import { ECNotification } from "./ec-notify.js"
// import { Router } from "@vaadin/router"

type Fields = {
  email?: string
  password?: string
}

@customElement('ec-reset-pw')
export class ECResetPW extends ECSignBase {

  @property({ type: Boolean })
  private notification: ECNotification | undefined = undefined
  
  static styles = [
    ...ECSignBase.styles,
    css`
    ec-notify {
      margin-top: 48px;
    }`
  ]

  render() {
    return html`
      <div class="main">
        <div id="content">
          <div id="callToJoin">Reset password</div>
          <div id="orSignIn">Or <a href="/users/_/signin">sign in</a> using current password.</div>
          <input id="email" type="text" placeholder="Email address"
                 data-part="email" @input=${this.validate}/>
          <button id="do" @click=${this.resetPW} part="button" ?disabled=${!this.valid}>Reset password</button>
          <ec-notify id="notify" .notification=${this.notification}></ec-notify>
        </div>
      </div>
    `
  }
  private async resetPW(_e: Event) {
    const fields: Fields = this.ui2jsObj('content')
    const { error } = await supabase.auth.resetPasswordForEmail(fields.email!, {
      redirectTo: 'https://example.com/update-password',
    })
    
    // console.log(`data=${JSON.stringify(data, null, 2)}; error=${JSON.stringify(error, null, 2)}`)
    if (error) {
      this.notification = {
        level: 'error',
        message: error?.message || 'Unknown password reset failure'
      }
    } else
      this.notification = undefined
    this.requestUpdate()
  }

  protected validate(_e: InputEvent) {
    super.validate(_e)
  }
}
