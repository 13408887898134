import { html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { ECComponent } from './ec-component.js'
import { ProtectedModule, User, protContext, userContext } from "./ec-app.js"
import { consume } from '@lit/context'

@customElement('ec-home')
export class ECHome extends ECComponent {
  @consume({context: userContext, subscribe: true})
  @property({attribute: false})
  public user?: User;
  
  @consume({context: protContext, subscribe: true})
  @property({attribute: false})
  public prot?: ProtectedModule

  render() {
    if (this.user?.signedIn)
    // return this.prot?.signedInHomeComponent
    return globalThis.signedInHomeComponent
    else
      return html`<ec-home-not-signed-in></ec-home-not-signed-in>`
  }
}
