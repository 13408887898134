import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ECComponent } from './ec-component';

type Level = 'info' | 'warning' | 'error' | 'success';
export type ECNotification = {
  level: Level
  message: string
}

@customElement('ec-notify')
export class ECNotify extends ECComponent {

  @property({ type: Object })
  notification: ECNotification | undefined = undefined

  static styles = [
    ...ECComponent.styles,
    css`
      :host {
        display: block;
        margin-top: 10px;
        border-width: 2px;
        padding: 8px;
      }
      .info {
        background-color: #e7f3fe;
        color: #1e3a8a;
      }
      .warning {
        background-color: #fff8e5;
        color: #9c6c00;
      }
      .error {
        background-color: #fde7e9;
        color: #9c0006;
      }
      .success {
        background-color: #e6ffe6;
        color: #006600;
      }
      div.main {
        display: grid;
        grid-template-columns: auto 1fr;
        border: 2px solid;
      }
      div.level {
        font-weight: bold;
        text-align: center;
        align-items: center;
        padding: 0.75em;
      }
      div.message {
        text-align: left;
      }
    `
  ]

  render() {
    if (this.notification === undefined)
      return html``
    else {
      const levelIcons: {[key in Level]: string} = {
        info: 'info',
        warning: 'warning',
        error: 'error',
        success: 'thumb_up_alt',
      }
      const iconName = levelIcons[this.notification.level] || '';
      return html`
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
        <div class="main ${this.notification.level}">
          <div class="level"><span class="material-icons menuItemIcon">${iconName}</span></div>
          <div class="message">${this.notification.message}</div>
        </div>
      `
    }
  }
}